import type { api } from '@meterup/proto';
import { Badge, BodyMono2, ManufacturerIcon, SmallMono2 } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchClients } from '../../../../api/api';
import { Page, PageHeader, PageSection, PageTitle } from '../../../../components/Page/Page';
import { AutoTable2 } from '../../../../components/Table/AutoTable2';
import { createColumnBuilder } from '../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../nav';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { routes } from '../../../../routes';
import { styled } from '../../../../stitches';
import {
  clientNameOrNull,
  isOnline,
  isWireless,
  manufacturerIconName,
} from '../../../../utils/clientLists';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';

const Box = styled('div');

const builder = createColumnBuilder<api.UserClient>();

const columns = [
  builder.display({
    meta: {
      sizingMode: 'fit-min',
      alignment: 'end',
    },
    cell: (p) => (
      <Badge
        arrangement="hidden-label"
        variant={isOnline(p.row) ? 'positive' : 'neutral'}
        icon={isWireless(p.row) ? 'wifi' : 'wired'}
        size="small"
        ends="pill"
      >
        {isOnline(p.row) ? 'Online' : 'Offline'}
      </Badge>
    ),
  }),
  builder.data((row) => row.name, {
    header: 'Name',
    minSize: 120,
    size: 300,
    meta: {
      isLeading: true,
      sizingMode: 'truncate',
    },
    cell: (p) => (
      <Box css={{ hStack: '$8' }}>
        <ManufacturerIcon icon={manufacturerIconName(p.row)} size="small" />
        <Box css={{ truncate: true }}>{clientNameOrNull(p.row) ?? <BodyMono2>-</BodyMono2>}</Box>
      </Box>
    ),
  }),
  builder.data((row) => row.ip_address, {
    header: 'IP',
    meta: { sizingMode: 'fit-max', isCopyable: true },
    cell: (p) => <SmallMono2>{p.value}</SmallMono2>,
  }),
  builder.data((row) => row.mac_address, {
    header: 'MAC',
    meta: {
      sizingMode: 'fit-max',
      isCopyable: true,
    },
    cell: (p) => <SmallMono2>{p.value}</SmallMono2>,
  }),
  builder.data((d) => d.signal, {
    header: 'Signal (dBm)',
    meta: {
      sizingMode: 'fit-min',
    },
    cell: (p) =>
      isWireless(p.row) ? (
        <Badge
          arrangement="leading-icon"
          variant={p.value > -74 ? 'positive' : 'negative'}
          size="small"
          ends="pill"
        >
          {p.value.toFixed(0)}
        </Badge>
      ) : (
        <SmallMono2>-</SmallMono2>
      ),
  }),
];

export const Meta = () => ({
  path: '/clients',
});

export default function ClientsPage() {
  const controller = useCurrentController();

  const clients =
    useQuery(['clients', controller], () => fetchClients(controller), { suspense: true }).data ??
    [];

  const params = Nav.useRegionParams('drawer', routes.drawers.clients.detail.path);

  const closeDrawer = useCloseDrawerCallback();

  return (
    <Page css={{ gap: 0 }}>
      <PageHeader>
        <PageTitle>Clients</PageTitle>
      </PageHeader>
      <PageSection>
        <AutoTable2
          columns={columns}
          data={clients}
          getLinkTo={(row) =>
            makeDrawerLink(window.location.pathname, paths.drawers.ClientDetailPage, {
              macAddress: row.mac_address,
            })
          }
          isRowSelected={(row) => params?.macAddress === row.mac_address}
          onRowDeselect={closeDrawer}
        />
      </PageSection>
    </Page>
  );
}
