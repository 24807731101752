import { expectDefinedOrThrow, isDefined } from '@meterup/common';
import * as Sentry from '@sentry/react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { createContext, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';

import type { IdentityData } from '../api/types';
import { getIdentity } from '../api/api';
import { UnauthenticatedError } from '../errors';
import { COOKIE_NAME } from '../utils/cookies';
import { redirectToSignInPage } from '../utils/redirectToSignInPage';

async function fetchIdentityOrRedirect(returnPath: string): Promise<IdentityData> {
  // NOTE: We're merely checking if the cookie is present to constitute a
  // "session". If it is not set, portal will set it.
  if (!document.cookie.includes(COOKIE_NAME)) {
    redirectToSignInPage(returnPath);
  }

  const identity = await getIdentity();

  if (identity && identity?.permissions?.includes('dashboard')) {
    return identity;
  }

  redirectToSignInPage(returnPath);

  // We never reach this code. It's here to satisfy the type checker.
  return undefined as any;
}

export const IdentityDataContext = createContext<IdentityData>(null as any);

/**
 * Provides basic session info like the user's current identity as well as their
 * currently selected company. If this info is not yet available, this component
 * does not render its children.
 */
export const IdentityDataProvider: React.FC = ({ children }) => {
  const identity = useQuery('identity', () => fetchIdentityOrRedirect(window.location.pathname), {
    suspense: true,
  }).data;

  const LDClient = useLDClient();

  useEffect(() => {
    if (isDefined(LDClient)) {
      if (isDefined(identity)) {
        LDClient.identify({
          key: identity.id.toString(),
          email: identity.username,
        });
      } else {
        LDClient.identify({
          anonymous: true,
        });
      }
    }
  }, [LDClient, identity]);

  useEffect(() => {
    if (isDefined(Sentry) && isDefined(identity)) {
      Sentry.setUser({
        id: identity.id.toString(),
        email: identity.username,
        email_domain: identity.username.split('@')[1],
        company_slugs: identity.company_memberships.map((membership) => membership.company_slug),
      });
    }
  }, [identity]);

  expectDefinedOrThrow(
    identity,
    new UnauthenticatedError('User is not authenticated and did not redirect to sign in page'),
  );

  return <IdentityDataContext.Provider value={identity}>{children}</IdentityDataContext.Provider>;
};

export const useIdentity = () => useContext(IdentityDataContext);
