import type { Column, ColumnDef, Table } from '@tanstack/react-table';
import type { To } from 'react-router-dom';
import { isDefined } from '@meterup/common';
import { CopyCapsule, Table2 } from '@meterup/metric';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';

import { ReactRouterLink } from '../ReactRouterLink';

const getSizeProps = (table: Table<any>, column: Column<any>) => {
  const meta = column.columnDef.meta ?? {};
  const { sizingMode = 'fit-max', alignment = 'flex-start' } = meta;
  const size = column.getSize();

  return {
    sizingMode,
    alignment,
    style: {
      minWidth: column.columnDef.minSize,
      width: sizingMode !== 'fit-min' ? `${(size / table.getTotalSize()) * 100}%` : undefined,
    },
  };
};

interface AutoTable2Props<D> {
  data: D[];
  columns: ColumnDef<D, any>[];
  getLinkTo?: (row: D) => To;
  isRowSelected?: (row: D) => boolean;
  onRowDeselect?: (row: D) => void;
}

export const AutoTable2 = <D extends any>({
  columns,
  data,
  getLinkTo,
  isRowSelected,
  onRowDeselect,
}: AutoTable2Props<D>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  });

  const isNavigableTable = isDefined(getLinkTo);

  return (
    <Table2.Table>
      <Table2.Head>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table2.HeadRow key={headerGroup.id}>
            <Table2.SelectedRowIndicatorHeadCell />
            {headerGroup.headers.map((header) => (
              <Table2.HeadCell key={header.id} {...getSizeProps(table, header.column)}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
              </Table2.HeadCell>
            ))}
            {isNavigableTable && <Table2.HeadCell />}
          </Table2.HeadRow>
        ))}
      </Table2.Head>
      <Table2.Body>
        {table.getRowModel().rows.map((row) => {
          const to = getLinkTo?.(row.original);
          const isSelected = isRowSelected?.(row.original) ?? false;

          const deselect = (e: React.MouseEvent) => {
            if (onRowDeselect) {
              e.preventDefault();
              onRowDeselect?.(row.original);
            }
          };

          return (
            <Table2.Row
              as={isDefined(to) ? ReactRouterLink : undefined}
              to={to}
              key={row.id}
              isSelected={isSelected}
              tabIndex={0}
            >
              <Table2.SelectedRowIndicatorCell isSelected={isSelected} />
              {row.getVisibleCells().map((cell) => {
                const content = flexRender(cell.column.columnDef.cell, cell.getContext());
                return (
                  <Table2.Cell
                    key={cell.id}
                    {...getSizeProps(table, cell.column)}
                    isLeading={cell.column.columnDef.meta?.isLeading}
                  >
                    {cell.column.columnDef.meta?.isCopyable ? (
                      <CopyCapsule
                        aria-label={`Copy ${cell.column.columnDef.header} to clipboard`}
                        arrangement={
                          cell.column.columnDef.meta?.alignment === 'end'
                            ? 'leading-icon'
                            : 'leading-label'
                        }
                        textValue={`${cell.getValue()}`}
                      >
                        {content}
                      </CopyCapsule>
                    ) : (
                      content
                    )}
                  </Table2.Cell>
                );
              })}
              {isNavigableTable &&
                (isSelected ? (
                  <Table2.DeselectRowCell onClick={deselect} />
                ) : (
                  <Table2.SelectRowCell />
                ))}
            </Table2.Row>
          );
        })}
      </Table2.Body>
    </Table2.Table>
  );
};
