import { breakpoints, Button, HStack, space, VStack } from '@meterup/metric';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchDevicesWithRadioData, fetchStatus } from '../../api/api';
import { useViewport } from '../../hooks/useViewport';
import { Nav } from '../../nav';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { routes } from '../../routes';
import { styled } from '../../stitches';
import Loading from '../Loading/Loading';
import {
  OldPageContainer,
  Page,
  PageControls,
  PageHeader,
  PageSection,
  PageTitle,
} from '../Page/Page';
import { DeviceListWidget } from '../Widgets/DeviceListWidget';
import { SpeedTestWidget } from '../Widgets/SpeedTestWidget';
import { StatusWidget } from '../Widgets/StatusWidget';

interface OverviewLayoutProps {
  speedTest: React.ReactNode;
  status: React.ReactNode;
  devices: React.ReactNode;
}

const MobileOverviewLayout: React.FC<OverviewLayoutProps> = ({ speedTest, status, devices }) => (
  <VStack width="full" spacing={space(16)}>
    {speedTest}
    {status}
    {devices}
  </VStack>
);
const SpeedAndStatusBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
  width: '100%',
  minWidth: 240,
  maxWidth: 340,
  flex: 2,
});

const DevicesBox = styled('div', {
  width: '100%',
  minWidth: 0,
  flex: 3,
});

const DesktopOverviewLayout: React.FC<OverviewLayoutProps> = ({ speedTest, status, devices }) => (
  <HStack width="full" spacing={space(16)}>
    <SpeedAndStatusBox>
      {speedTest}
      {status}
    </SpeedAndStatusBox>
    <DevicesBox>{devices}</DevicesBox>
  </HStack>
);

const OverviewLayout: React.FC<OverviewLayoutProps> = (props) => {
  const { width } = useViewport();
  const isMobile = width < breakpoints.lg;
  return isMobile ? <MobileOverviewLayout {...props} /> : <DesktopOverviewLayout {...props} />;
};

const Overview = () => {
  const controller = useCurrentController();

  const status = useQuery(['status', controller], () => fetchStatus(controller), {
    suspense: true,
  }).data;

  const devices = useQuery(['devices', controller], () => fetchDevicesWithRadioData(controller), {
    suspense: true,
  }).data;

  const sortedDevices = orderBy(devices, ['clients'], ['desc']);

  if (status && devices) {
    return (
      <Page>
        <PageSection>
          <PageHeader>
            <PageTitle>Overview</PageTitle>
            <PageControls>
              <Button
                variant="secondary"
                as={Nav.Link}
                to={{ drawer: routes.drawers.clients.add.path }}
              >
                Show wireless info
              </Button>
            </PageControls>
          </PageHeader>
          <OldPageContainer>
            <VStack width="full" spacing={space(16)}>
              <OverviewLayout
                speedTest={
                  <SpeedTestWidget
                    bandwidthUpdatedAt={
                      status.bandwidth_updated_at ? new Date(status.bandwidth_updated_at) : null
                    }
                    downloadBytes={status.download_bytes_per_second}
                    uploadBytes={status.upload_bytes_per_second}
                  />
                }
                status={
                  <StatusWidget
                    numClients={status.connected_clients}
                    numDevices={status.connected_devices}
                  />
                }
                devices={<DeviceListWidget devices={sortedDevices} />}
              />
            </VStack>
          </OldPageContainer>
        </PageSection>
      </Page>
    );
  }

  return <Loading />;
};

export default Overview;
