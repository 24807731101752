import { isDefined } from '@meterup/common';
import {
  Alert,
  BasicSelectItem,
  Body2,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';
import { useQuery } from 'react-query';
import { Route } from 'react-router-dom';

import type { UserTokenResponseJSON } from '../../../api/types';
import {
  fetchNetworkInfo,
  getCompanyUsers,
  getOrCreateSingleToken,
  getOrCreateStandaloneToken,
  getUser,
} from '../../../api/api';
import { useDrawerLocation } from '../../../hooks/useDrawerLocation';
import { useDrawerParams } from '../../../hooks/useDrawerParams';
import { useNavigateMainAndDrawerCallback } from '../../../hooks/useNavigateMainAndDrawerCallback';
import { Nav } from '../../../nav';
import {
  useCurrentCompany,
  useCurrentCompanyMembership,
} from '../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../providers/CurrentControllerProvider';
import { routes } from '../../../routes';
import { getWiFiQRCodeConnectionString } from '../../../utils/getWiFiQRCodeConnectionString';
import { CollapsibleList } from '../../CollapsibleList';
import { NavigatingSmartLinkButton } from '../../Setup/NavigatingButton';
import { BasicSelectField } from '../units';
import { DrawerContentStack } from './DrawerContentStack';
import { PasswordViewerListItem } from './PasswordViewerListItem';
import { QRCodeListItem } from './QRCodeListItem';

const MeterAuthTokenInfo = ({ token }: { token: UserTokenResponseJSON }) => {
  const controller = useCurrentController();

  const { data: networkInfo } = useQuery(
    ['network_info', controller],
    () => fetchNetworkInfo(controller),
    {
      suspense: true,
      retry: false,
    },
  );

  return isDefined(networkInfo) ? (
    <CollapsibleList title="Client-specific authentication" defaultOpen>
      <QRCodeListItem
        text={getWiFiQRCodeConnectionString(networkInfo.private_ssid, token.user_token)}
      />
      <ListItemPair>
        <ListItemLabel>SSID</ListItemLabel>
        <ListItemValue>{networkInfo.private_ssid}</ListItemValue>
      </ListItemPair>
      <PasswordViewerListItem label="Unique password" password={token.user_token} />
    </CollapsibleList>
  ) : null;
};

const AddClientSelf = ({ sid }: { sid: string }) => {
  const { data: token } = useQuery(['user_token', sid], () => getOrCreateSingleToken(sid), {
    suspense: true,
  });

  return isDefined(token) ? (
    <DrawerContentStack>
      <Alert
        variant="alternative"
        icon="secure"
        heading="Unique access info"
        copy="This is your unique access token to the network. Use the QR code or directly enter the password provided below to attach your new client to your Meter Network."
      />
      <MeterAuthTokenInfo token={token} />
    </DrawerContentStack>
  ) : null;
};

const AddClientUser = ({ sid }: { sid: string }) => {
  const company = useCurrentCompany();
  const { data: user } = useQuery(['user', sid], () => getUser(company, sid), {
    suspense: true,
  });

  const { data: token } = useQuery(['user_token', sid], () => getOrCreateSingleToken(sid), {
    suspense: true,
  });

  return isDefined(user) && isDefined(token) ? (
    <DrawerContentStack>
      <Alert
        variant="alternative"
        icon="secure"
        heading="Unique access info"
        copy={
          <>
            This is the unique access token for <strong>{user.email}</strong>. Use the QR code or
            directly enter the password provided below to attach their new client to your Meter
            Network.
          </>
        }
      />
      <MeterAuthTokenInfo token={token} />
    </DrawerContentStack>
  ) : null;
};

const AddClientUserOrSelf = () => {
  const { userSid } = useDrawerParams(routes.drawers.clients.addForUser.path)!;
  const { sid } = useCurrentCompanyMembership();
  return userSid === sid ? <AddClientSelf sid={sid} /> : <AddClientUser sid={userSid as string} />;
};

const AddClientStandalone = () => {
  const { sid, company_slug: companySlug } = useCurrentCompanyMembership();
  const { data: token } = useQuery(
    ['standalone_token', companySlug, sid],
    () => getOrCreateStandaloneToken(companySlug, sid),
    {
      suspense: true,
    },
  );

  return isDefined(token) ? (
    <DrawerContentStack>
      <Alert
        variant="alternative"
        icon="secure"
        heading="Standalone device access info"
        copy="This is a unique token for all standalone devices on the network. Use the QR code or directly enter the password provided below to attach the new client to your Meter Network."
      />
      <MeterAuthTokenInfo token={token} />
    </DrawerContentStack>
  ) : null;
};

const AddClientForUserStart = () => {
  const company = useCurrentCompany();
  const { data: userList } = useQuery(['users', company], () => getCompanyUsers(company), {
    suspense: true,
  });

  const users = userList?.users ?? [];

  const navigate = useNavigateMainAndDrawerCallback();

  return (
    <DrawerContentStack>
      <BasicSelectField
        label="User"
        defaultValue="placeholder"
        onValueChange={(key) =>
          navigate({ drawer: routes.drawers.clients.addForUser.pathTo(key as string) })
        }
      >
        <BasicSelectItem value="placeholder" disabled>
          Select a user
        </BasicSelectItem>
        {users?.map((user) => (
          <BasicSelectItem key={user.sid} value={user.sid}>
            {user.email}
          </BasicSelectItem>
        ))}
      </BasicSelectField>
    </DrawerContentStack>
  );
};

const ChooseTokenType = () => {
  const { sid } = useCurrentCompanyMembership();
  return (
    <DrawerContentStack>
      <Body2 css={{ fontWeight: 600 }}>What sort of client are you adding?</Body2>
      <NavigatingSmartLinkButton
        label="Personal device"
        description="Your laptop, phone, etc."
        to={{ drawer: routes.drawers.clients.addForUser.pathTo(sid) }}
      />
      <NavigatingSmartLinkButton
        label="Device for someone else"
        description="Their laptop, phone, etc."
        to={{ drawer: routes.drawers.clients.addForUserStart.path }}
      />
      <NavigatingSmartLinkButton
        label="Standalone device"
        description="Printer, speaker, robot, etc."
        to={{ drawer: routes.drawers.clients.addStandalone.path }}
      />
    </DrawerContentStack>
  );
};

export const MeterAuthTokenWizard = () => {
  const currentMembership = useCurrentCompanyMembership();
  const drawerLocation = useDrawerLocation();

  return isDefined(drawerLocation) &&
    currentMembership.user_role === api.CompanyMembershipRole.admin ? (
    <Nav.Routes regionName="drawer">
      <Route path={routes.drawers.clients.addForUser.path} element={<AddClientUserOrSelf />} />
      <Route path={routes.drawers.clients.addStandalone.path} element={<AddClientStandalone />} />
      <Route
        path={routes.drawers.clients.addForUserStart.path}
        element={<AddClientForUserStart />}
      />
      <Route path={routes.drawers.clients.add.path} element={<ChooseTokenType />} />
    </Nav.Routes>
  ) : (
    <AddClientSelf sid={currentMembership.sid} />
  );
};
