import type { BadgeVariant } from '@meterup/metric';
import { Badge } from '@meterup/metric';
import { zxcvbn, ZxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import React, { useEffect, useState } from 'react';

const options = {
  translations: zxcvbnEnPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
};

ZxcvbnOptions.setOptions(options);

enum PasswordStrength {
  Pending,
  VeryWeak,
  Weak,
  Good,
  Strong,
  VeryStrong,
}

const getPasswordStrengthData = (
  strength: PasswordStrength,
  // eslint-disable-next-line consistent-return
): { label: string; variant: BadgeVariant } => {
  // eslint-disable-next-line default-case
  switch (strength) {
    case PasswordStrength.Pending:
      return { label: 'Pending', variant: 'neutral' };
    case PasswordStrength.VeryWeak:
      return { label: 'Very Weak', variant: 'negative' };
    case PasswordStrength.Weak:
      return { label: 'Weak', variant: 'negative' };
    case PasswordStrength.Good:
      return { label: 'Good', variant: 'neutral' };
    case PasswordStrength.Strong:
      return { label: 'Strong', variant: 'positive' };
    case PasswordStrength.VeryStrong:
      return { label: 'Very Strong', variant: 'positive' };
  }
};

export const PasswordStrengthIndicator: React.FC<{ password: string }> = ({ password }) => {
  const [result, setResult] = useState<PasswordStrength>(PasswordStrength.Pending);

  useEffect(() => {
    const checkAndSetResult = async () => setResult((await zxcvbn(password)).score + 1);
    checkAndSetResult();
  }, [password]);

  const { variant, label } = getPasswordStrengthData(result);

  return (
    <Badge variant={variant} size="small">
      {label}
    </Badge>
  );
};
