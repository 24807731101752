import { expectDefinedOrThrow, isDefined, isDefinedAndNotEmpty } from '@meterup/common';
import {
  Badge,
  BodyMono2,
  CopyCapsule,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  ManufacturerIcon,
  MinList,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
  Subheading2,
} from '@meterup/metric';
import { orderBy } from 'lodash';
import oui from 'oui';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchClientConnectionHistory, fetchDevice } from '../../../../api/api';
import { ConnectionStatusBadge } from '../../../../components/badges';
import { BandIconAndBadge } from '../../../../components/BandIconAndBadge';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton';
import { NotFoundError } from '../../../../errors';
import { Nav } from '../../../../nav';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { routes } from '../../../../routes';
import { styled } from '../../../../stitches';
import {
  clientNameOrNull,
  getClientConnectionStatus,
  isWireless,
  manufacturerIconName,
} from '../../../../utils/clientLists';

const NoValue = () => <BodyMono2>-</BodyMono2>;

const ClientName = styled('div', {
  vStack: '$8',
  alignItems: 'center',
});

export const Meta = () => ({
  path: '/clients/:macAddress',
});

export default function ClientDetailPage() {
  const controller = useCurrentController();
  const { macAddress } = Nav.useRegionParams('drawer', routes.drawers.clients.detail.path)!;

  const { data: clientHistory } =
    useQuery(
      ['client_history', macAddress],
      () => fetchClientConnectionHistory(controller, macAddress),
      {
        suspense: true,
      },
    ) ?? [];

  const client = orderBy(clientHistory, (d) => d.last_seen, 'desc')[0] ?? null;

  expectDefinedOrThrow(client, new NotFoundError('Client not found'));

  const { data: device } = useQuery(
    ['device', controller, client.apname],
    () => fetchDevice(controller, client.apname),
    {
      suspense: true,
      enabled: isDefinedAndNotEmpty(client.apname),
    },
  );

  const manufacturer = oui(client.mac_address);

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Client</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <ClientName>
          <BandIconAndBadge
            size="medium"
            variant={getClientConnectionStatus(client) === 'online' ? 'positive' : 'neutral'}
            badge={<ConnectionStatusBadge status={getClientConnectionStatus(client)} />}
            icon={<ManufacturerIcon icon={manufacturerIconName(client)} size="medium" />}
          />
          <Subheading2>{clientNameOrNull(client) ?? '-'}</Subheading2>
        </ClientName>
        <MinList>
          <MinListItemHeader>
            <MinListTitle>Client</MinListTitle>
          </MinListItemHeader>
          <MinListItemPair>
            <MinListItemLabel>Hostname</MinListItemLabel>
            <MinListItemValue>
              {clientNameOrNull(client) ? (
                <CopyCapsule
                  aria-label="Copy hostname to clipboard"
                  arrangement="leading-icon"
                  textValue={client.name}
                >
                  {client.name}
                </CopyCapsule>
              ) : (
                <NoValue />
              )}
            </MinListItemValue>
          </MinListItemPair>
          <MinListItemPair>
            <MinListItemLabel>Manufacturer</MinListItemLabel>
            <MinListItemValue>{manufacturer ?? <NoValue />}</MinListItemValue>
          </MinListItemPair>
          <MinListItemPair>
            <MinListItemLabel>IP</MinListItemLabel>
            <MinListItemValue>
              <BodyMono2>
                <CopyCapsule
                  aria-label="Copy IP address to clipboard"
                  arrangement="leading-icon"
                  textValue={client.ip_address}
                >
                  {client.ip_address}
                </CopyCapsule>
              </BodyMono2>
            </MinListItemValue>
          </MinListItemPair>
          <MinListItemPair>
            <MinListItemLabel>MAC</MinListItemLabel>
            <MinListItemValue>
              <BodyMono2>
                <CopyCapsule
                  aria-label="Copy MAC address to clipboard"
                  arrangement="leading-icon"
                  textValue={client.mac_address}
                >
                  {client.mac_address}
                </CopyCapsule>
              </BodyMono2>
            </MinListItemValue>
          </MinListItemPair>
        </MinList>
        {isWireless(client) && (
          <MinList>
            <MinListItemHeader>
              <MinListTitle>Connection</MinListTitle>
            </MinListItemHeader>
            <MinListItemPair>
              <MinListItemLabel>Access point</MinListItemLabel>
              <MinListItemValue>
                {isDefined(device) ? (
                  <Badge
                    icon="accessPoint"
                    arrangement="leading-icon"
                    variant={device.status === 'online' ? 'positive' : 'neutral'}
                    size="small"
                    ends="pill"
                  >
                    {device.physical_location}
                  </Badge>
                ) : (
                  <NoValue />
                )}
              </MinListItemValue>
            </MinListItemPair>
            <MinListItemPair>
              <MinListItemLabel>Signal</MinListItemLabel>
              <MinListItemValue>
                {client.signal !== 0 ? (
                  <Badge
                    arrangement="leading-icon"
                    variant={client.signal > -74 ? 'positive' : 'negative'}
                    size="small"
                    ends="pill"
                  >
                    {client.signal}
                  </Badge>
                ) : (
                  <NoValue />
                )}
              </MinListItemValue>
            </MinListItemPair>

            <MinListItemPair>
              <MinListItemLabel>Noise</MinListItemLabel>
              <MinListItemValue>
                {client.noise !== 0 ? (
                  <Badge arrangement="leading-icon" variant="neutral" size="small" ends="pill">
                    {client.noise}
                  </Badge>
                ) : (
                  <NoValue />
                )}
              </MinListItemValue>
            </MinListItemPair>

            <MinListItemPair>
              <MinListItemLabel>Channel</MinListItemLabel>
              <MinListItemValue>
                {client.channel !== 0 ? (
                  <Badge arrangement="leading-icon" variant="neutral" size="small" ends="pill">
                    {client.channel}
                  </Badge>
                ) : (
                  <NoValue />
                )}
              </MinListItemValue>
            </MinListItemPair>
          </MinList>
        )}
      </DrawerContent>
    </Drawer>
  );
}
