import {
  BasicSelectItem,
  darkThemeSelector,
  FocusRingSelf,
  Icon,
  Select,
  SelectContent,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  Small2,
} from '@meterup/metric';
import React from 'react';

import {
  useChangeCurrentControllerCallback,
  useCurrentController,
} from '../providers/CurrentControllerProvider';
import { colors, fontWeights, styled } from '../stitches';
import { useControllerList } from './Navigation/useControllerList';

const StyledIcon = styled(Icon, {
  color: colors['gray-600'],
  width: '$12',
  height: '$12',
  [darkThemeSelector]: {
    color: colors['gray-200'],
  },
});

const MenuArrow = styled(StyledIcon, {
  color: colors['gray-500'],
  [darkThemeSelector]: {
    color: colors['gray-300'],
  },
});

const ValueContainer = styled('div', Small2, {
  fontWeight: fontWeights.medium,
  truncate: true,
  color: colors['gray-700'],
  [darkThemeSelector]: {
    color: colors['gray-100'],
  },
});

const Trigger = styled('button', FocusRingSelf, {
  maxWidth: 236,
  borderRadius: '$6',
  hStack: '$6',
  padding: '$6 $8',
});

export const LocationSwitcher = () => {
  const controllers = useControllerList();
  const currentController = useCurrentController();
  const changeCurrentController = useChangeCurrentControllerCallback();

  return (
    <Select value={currentController} onValueChange={changeCurrentController}>
      <SelectTrigger asChild>
        <Trigger type="button">
          <StyledIcon icon="location" />
          <ValueContainer>
            <SelectValue />
          </ValueContainer>
          <MenuArrow icon="arrowsVertical" />
        </Trigger>
      </SelectTrigger>
      <SelectContent>
        <SelectScrollUpButton />
        <SelectViewport>
          {controllers.map((controller) => (
            <BasicSelectItem key={controller.name} value={controller.name}>
              {controller.address}
            </BasicSelectItem>
          ))}
        </SelectViewport>
        <SelectScrollDownButton />
      </SelectContent>
    </Select>
  );
};
