import {
  Alert,
  BasicSelectItem,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import { Formik } from 'formik';
import React, { Suspense } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { createToken, getCompanyUsers } from '../../api/api';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNavigateMainAndDrawerCallback } from '../../hooks/useNavigateMainAndDrawerCallback';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useIdentity } from '../../providers/IdentityDataProvider';
import { routes } from '../../routes';
import { styled } from '../../stitches';
import { createTypedField } from '../../utils/createTypedField';
import { CloseDrawerButton } from '../CloseDrawerButton';
import { LoadingDrawer } from './LoadingDrawer';
import { BasicSelectField, NumberField, TextField } from './units';

const validCreateTokenFormData = z.object({
  userId: z.string(),
  alias: z.string().optional(),
  maxClientsLimit: z.number({ invalid_type_error: 'Required' }),
});

export interface CreateTokenFormData extends z.TypeOf<typeof validCreateTokenFormData> {}

const TypedField = createTypedField<CreateTokenFormData>();

const FormContent = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
});

const StyledForm = styled('form', {
  display: 'contents',
});

const DRAWER_TITLE = 'Create client token';

const CreateTokenDrawerForm = () => {
  const closeDrawer = useCloseDrawerCallback();
  const company = useCurrentCompany();
  const identity = useIdentity();

  const { data: userList } = useQuery(['users', company], () => getCompanyUsers(company), {
    suspense: true,
  });

  const users = userList?.users ?? [];
  const currentUserSid = users?.find((u) => u.email === identity.username)?.sid ?? '';

  const client = useQueryClient();
  const navigateDrawer = useNavigateMainAndDrawerCallback();

  const createTokenMutation = useMutation(
    (data: CreateTokenFormData) =>
      createToken({
        alias: data.alias ?? '',
        max_clients_limit: data.maxClientsLimit,
        user_sid: data.userId,
      }),
    {
      onSuccess: (d) => {
        if (d) {
          navigateDrawer({
            drawer: routes.drawers.clientTokens.detail.pathTo(d.user_sid, d.sid),
          });
          client.invalidateQueries(['company_users']);
        }
      },
    },
  );

  return (
    <Formik<CreateTokenFormData>
      validationSchema={toFormikValidationSchema(validCreateTokenFormData)}
      initialValues={{
        userId: currentUserSid,
        alias: '',
        maxClientsLimit: 1,
      }}
      onSubmit={(values) => {
        createTokenMutation.mutate(values);
      }}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>{DRAWER_TITLE}</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>
            <DrawerContent>
              <FormContent>
                {createTokenMutation.error && (
                  <Alert
                    icon="attention"
                    variant="negative"
                    copy="There was an issue creating the token. Please try again."
                  />
                )}
                <TypedField name="userId">
                  {({ field }) => (
                    <BasicSelectField
                      label="User"
                      id={field.name}
                      name={field.name}
                      value={field.value}
                      defaultValue={users[0]?.sid ?? ''}
                      onValueChange={(key) => form.setFieldValue(field.name, key)}
                      errorMessage={form.touched.userId && form.errors.userId}
                    >
                      {users?.map((user) => (
                        <BasicSelectItem key={user.sid} value={user.sid}>
                          {user.email}
                        </BasicSelectItem>
                      ))}
                    </BasicSelectField>
                  )}
                </TypedField>
                <TypedField name="alias">
                  {({ field }) => (
                    <TextField
                      label="Token alias"
                      {...field}
                      onChange={(v) => form.setFieldValue(field.name, v)}
                      errorMessage={form.touched.alias && form.errors.alias}
                    />
                  )}
                </TypedField>
                <TypedField name="maxClientsLimit">
                  {({ field }) => (
                    <NumberField
                      label="Max number of devices"
                      {...field}
                      onChange={(v) => form.setFieldValue(field.name, v)}
                      errorMessage={form.touched.maxClientsLimit && form.errors.maxClientsLimit}
                    />
                  )}
                </TypedField>
              </FormContent>
            </DrawerContent>
            <DrawerFooter>
              <DrawerControls>
                <Button type="button" variant="secondary" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={!form.isValid}
                  loading={createTokenMutation.isLoading}
                >
                  Create
                </Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
};

export const CreateTokenDrawer = () => (
  <Suspense fallback={<LoadingDrawer title={DRAWER_TITLE} skeletonCount={3} />}>
    <CreateTokenDrawerForm />
  </Suspense>
);
