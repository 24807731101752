import type { ExtractRouteParams } from '@meterup/react-router-extensions';
import { generatePath } from 'react-router';

import { Nav } from '../nav';

export const makeLink = <T extends string>(path: T, args: ExtractRouteParams<T, string>): string =>
  // @ts-expect-error
  generatePath(path, args);

export const makeDrawerLink = <T extends string>(
  currentRootLocation: string,
  path: T,
  args: ExtractRouteParams<T, string>,
) => Nav.makeTo({ root: currentRootLocation, drawer: `${makeLink(path, args)}` });

export const makeCloseDrawerLink = (currentRootLocation: string) =>
  Nav.makeTo({ root: currentRootLocation, drawer: null });
