import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Header, HeaderLogo, HeaderNavItem } from '@meterup/metric';
import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';
import { useFocusVisible } from 'react-aria';
import { Outlet } from 'react-router';

import { ErrorFallback } from '../../components/ErrorFallback/ErrorFallback';
import { HeaderDropdownMenu } from '../../components/HeaderDropdownMenu';
import { LoadingFallback } from '../../components/LoadingFallback';
import { LocationSwitcher } from '../../components/LocationSwitcher';
import { ReactRouterLink } from '../../components/ReactRouterLink';
import { CurrentControllerProvider } from '../../providers/CurrentControllerProvider';
import { focusVisibleClassName, styled } from '../../stitches';

const Container = styled('div', {
  height: '100%',
  display: 'grid',
  position: 'relative',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content minmax(0, 1fr)',
  gridTemplateAreas: '"header" "content"',
});

const HeaderContainer = styled('div', {
  gridArea: 'header',
  display: 'block',
  zIndex: 3,
  '@maxSm': {
    display: 'none',
  },
});

const ContentContainer = styled('div', {
  gridArea: 'content',
  overflow: 'auto',
  zIndex: 1,
});

export const Meta: PagefileMetaFn = () => ({
  name: 'AppLayout',
});

export default function AppLayout() {
  const { isFocusVisible } = useFocusVisible();

  return (
    <CurrentControllerProvider>
      <Container className={isFocusVisible ? focusVisibleClassName : ''}>
        <HeaderContainer>
          <Header
            navigation={
              <>
                <LocationSwitcher />
                <div style={{ marginLeft: 8 }}>
                  <HeaderNavItem
                    as={ReactRouterLink}
                    to="/"
                    label="Network"
                    isSelected
                    icon="network"
                  />
                </div>
              </>
            }
            logo={<HeaderLogo />}
            controls={<HeaderDropdownMenu />}
          />
        </HeaderContainer>
        <ContentContainer>
          <ErrorBoundary fallback={ErrorFallback}>
            <Suspense fallback={<LoadingFallback />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </ContentContainer>
      </Container>
    </CurrentControllerProvider>
  );
}
