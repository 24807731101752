import type { SidebarLocationControlOption } from '@meterup/metric';
import {
  DesktopSidebar,
  DesktopSidebarLocationControlGroup,
  SidebarLocationControl,
  SidebarNavItem,
} from '@meterup/metric';
import React from 'react';
import { Item } from 'react-stately';

import { useLogoutHandler } from '../../hooks/useLogoutHandler';
import {
  useChangeCurrentControllerCallback,
  useCurrentController,
} from '../../providers/CurrentControllerProvider';
import { styled } from '../../stitches';
import { ScopedDesktopSidebarEntries } from './ScopedDesktopSidebar';
import { networkSidebarEntries } from './sidebarEntries';
import { useControllerList } from './useControllerList';

const LogoutButton = styled('div', { marginTop: 'auto', width: '100%', padding: '$12' });

const NetworkSidebarDesktop: React.FC = () => {
  const logout = useLogoutHandler();
  const currentController = useCurrentController();
  const changeCurrentController = useChangeCurrentControllerCallback();

  const controllers = useControllerList();

  const dropDownOptions: SidebarLocationControlOption[] = controllers
    .map((c) => ({
      value: c.name,
      label: c.address,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <DesktopSidebar>
      <DesktopSidebarLocationControlGroup>
        <SidebarLocationControl
          aria-label="Choose a location"
          selectedKey={currentController}
          onSelectionChange={(key) => changeCurrentController(key as string)}
        >
          {dropDownOptions.map((item) => (
            <Item key={item.value}>{item.label}</Item>
          ))}
        </SidebarLocationControl>
      </DesktopSidebarLocationControlGroup>

      <ScopedDesktopSidebarEntries entries={networkSidebarEntries} />

      <LogoutButton>
        <SidebarNavItem as="button" label="Log out" onClick={logout} />
      </LogoutButton>
    </DesktopSidebar>
  );
};

export default NetworkSidebarDesktop;
