import {
  isDefined,
  isDefinedAndNotEmpty,
  PanAndZoomRegion,
  preloadImage,
  shadows,
} from '@meterup/common';
import { HStack, space, Tab, Tabs } from '@meterup/metric';
import { styled } from '@meterup/metric/src/stitches.config';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import type { TabFilter } from '../Table/TabFilters';
import { fetchDevicesWithRadioData, fetchFloorPlan } from '../../api/api';
import { paths } from '../../constants';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { Nav } from '../../nav';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { routes } from '../../routes';
import { colors } from '../../stitches';
import {
  DEVICE_LIST_COLUMNS,
  DevicesFilterStrategy,
  getActiveFilter,
  isOffline,
  isOnline,
} from '../../utils/access_point_utils';
import { Page, PageSection } from '../Page/Page';
import { TabFilters } from '../Table/TabFilters';
import { Table, TableTopBar, TableTopBarTabs } from '../Table/Table';

const Box = styled('div');

const PanningContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  boxShadow: shadows.fenceAllLight,
  padding: 0.5,
});

const AccessPoints = () => {
  const controller = useCurrentController();
  const closeDrawer = useCloseDrawerCallback();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointDetail);

  const [currentView, setCurrentView] = useState('list');

  const clients = useQuery(
    ['devices_and_radios', controller],
    () => fetchDevicesWithRadioData(controller),
    {
      suspense: true,
    },
  );

  const allDevices = clients.data ?? [];

  const [filterStrategy, setFilterStrategy] = useState<DevicesFilterStrategy>(
    DevicesFilterStrategy.All,
  );

  const floorPlanURL = useQuery(
    ['floor_plan', controller],
    async () => {
      const url = await fetchFloorPlan(controller);
      return url ? preloadImage(url) : null;
    },
    { suspense: true },
  ).data;

  const filters: TabFilter<DevicesFilterStrategy>[] = [
    {
      key: DevicesFilterStrategy.All,
      label: 'All',
      count: allDevices.length,
    },
    {
      key: DevicesFilterStrategy.Online,
      label: 'Online',
      count: allDevices.filter(isOnline).length,
    },
    {
      key: DevicesFilterStrategy.Offline,
      label: 'Offline',
      count: allDevices.filter(isOffline).length,
    },
  ];

  const filter = getActiveFilter(filterStrategy);
  const filteredDevices = allDevices.filter(filter);

  return (
    <Page>
      <PageSection>
        {currentView === 'list' ? (
          <Table
            columns={DEVICE_LIST_COLUMNS}
            data={filteredDevices}
            tabs={
              <HStack spacing={space(12)} align="center">
                <Tabs>
                  <Tab active onClick={() => setCurrentView('list')} icon="menu">
                    List
                  </Tab>
                  {floorPlanURL && (
                    <Tab onClick={() => setCurrentView('floorplan')} icon="floorplan">
                      Floor Plan{' '}
                    </Tab>
                  )}
                </Tabs>
                <Box
                  css={{
                    width: '2px',
                    height: '16px',
                    background: colors['gray-100'],
                    borderRadius: '8px',
                  }}
                />
                <TabFilters
                  filters={filters}
                  activeFilterKey={filterStrategy}
                  onActivateFilter={({ key }) => setFilterStrategy(key)}
                />
              </HStack>
            }
            linkProps={(d) =>
              isDefinedAndNotEmpty(d.device.name)
                ? {
                    to: {
                      drawer: routes.drawers.devices.detail.pathTo(d.device.name),
                    },
                  }
                : null
            }
            onRowDeselect={closeDrawer}
            isRowSelected={(d) => d.device.name === drawerParams?.deviceName}
            emptyStateHeading="No devices"
          />
        ) : (
          <>
            <TableTopBar>
              <TableTopBarTabs>
                <Tabs>
                  <Tab onClick={() => setCurrentView('list')} icon="menu">
                    List
                  </Tab>
                  {floorPlanURL && (
                    <Tab active onClick={() => setCurrentView('floorplan')} icon="floorplan">
                      Floor Plan
                    </Tab>
                  )}
                </Tabs>
              </TableTopBarTabs>
            </TableTopBar>
            <PanningContainer>
              <PanAndZoomRegion>
                {isDefined(floorPlanURL) && (
                  <img
                    src={floorPlanURL}
                    alt="Floor plan"
                    style={{ maxHeight: '50vh', userSelect: 'none' }}
                  />
                )}
              </PanAndZoomRegion>
            </PanningContainer>
          </>
        )}
      </PageSection>
    </Page>
  );
};

export default AccessPoints;
