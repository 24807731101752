import type { CellProps, Column } from 'react-table';
import {
  DisabledBadge,
  getEnabledRadioForBandOrNull,
  isDefined,
  NeutralBadge,
  Tooltip,
} from '@meterup/common';
import { Badge, DeviceIcon, DeviceIconSize, HStack, space } from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';

import type { DeviceDataAndRadios } from '../api/types';
import { CellDeviceStatus } from '../components/Table/tableCells';
import { styled } from '../stitches';

const DeviceName = styled('div', { hStack: '$8' });

const RadioNumbers = ({ value }: { value: api.AccessPointRadioResponse }) => (
  <HStack spacing={space(4)}>
    <Tooltip content="Channel">
      <Badge size="small">{value.channel}</Badge>
    </Tooltip>
    <Tooltip content="Channel Width">
      <Badge size="small">{value.channel_width}</Badge>
    </Tooltip>
    <Tooltip content="Power">
      <Badge size="small">{value.power}</Badge>
    </Tooltip>{' '}
  </HStack>
);

const hasDefinedRadio = (device: DeviceDataAndRadios | null | undefined) =>
  isDefined(device) &&
  isDefined(device.access_points_radios) &&
  device.access_points_radios.length > 0;

export const DEVICE_LIST_COLUMNS: Column<DeviceDataAndRadios>[] = [
  {
    Header: 'Location',
    accessor: (d) => d.device.physical_location ?? d.device.name,
    Cell: ({ value }: { value: string }) => (
      <DeviceName>
        <DeviceIcon size={DeviceIconSize.Small} /> {value}
      </DeviceName>
    ),
  },
  {
    Header: '5G band',
    accessor: (d) => {
      if (hasDefinedRadio(d)) {
        const radio = getEnabledRadioForBandOrNull(d.access_points_radios[0], api.RadioBand.RB_5G);
        return radio
          ? [radio.channel.toString(), radio.channel_width.toString(), radio.power.toString()].join(
              ', ',
            )
          : null;
      }
      return null;
    },
    Cell: (props: CellProps<any>) => {
      if (hasDefinedRadio(props.row.original)) {
        const value = getEnabledRadioForBandOrNull(
          props.row.original.access_points_radios[0],
          api.RadioBand.RB_5G,
        );
        return value ? <RadioNumbers value={value} /> : <DisabledBadge />;
      }
      return <DisabledBadge />;
    },
  },
  {
    Header: '2.4G band',
    accessor: (d) => {
      if (hasDefinedRadio(d)) {
        const radio = getEnabledRadioForBandOrNull(d.access_points_radios[0], api.RadioBand.RB_2G);
        return radio
          ? [radio.channel.toString(), radio.channel_width.toString(), radio.power.toString()].join(
              ', ',
            )
          : null;
      }
      return null;
    },
    Cell: (props: CellProps<any>) => {
      if (hasDefinedRadio(props.row.original)) {
        const value = getEnabledRadioForBandOrNull(
          props.row.original.access_points_radios[0],
          api.RadioBand.RB_2G,
        );
        return value ? <RadioNumbers value={value} /> : <DisabledBadge />;
      }
      return <DisabledBadge />;
    },
  },
  {
    Header: 'Version',
    accessor: (d) => {
      if (hasDefinedRadio(d)) {
        return d.access_points_radios[0].access_point?.version;
      }
      return null;
    },
    Cell: ({ value }: { value: string }) => <NeutralBadge>{value}</NeutralBadge>,
  },
  {
    Header: 'Status',
    accessor: (d) => d.device.status,
    Cell: CellDeviceStatus,
  },
  {
    Header: 'Clients',
    accessor: (d) => d.device.clients,
  },
];

export enum DevicesFilterStrategy {
  All = 'all',
  Online = 'online',
  Offline = 'offline',
  Draft = 'draft',
}

export const isOnline = (d: DeviceDataAndRadios) =>
  d.device.status === DevicesFilterStrategy.Online;
export const isOffline = (d: DeviceDataAndRadios) =>
  d.device.status === DevicesFilterStrategy.Offline;
export const isDraft = (d: DeviceDataAndRadios) => d.device.status === DevicesFilterStrategy.Draft;

export const getActiveFilter = (
  strategy: DevicesFilterStrategy,
  // eslint-disable-next-line consistent-return
): ((d: DeviceDataAndRadios) => boolean) => {
  switch (strategy) {
    case DevicesFilterStrategy.All:
      return () => true;
    case DevicesFilterStrategy.Online:
      return isOnline;
    case DevicesFilterStrategy.Offline:
      return isOffline;
    case DevicesFilterStrategy.Draft:
      return isDraft;
  }
};
