import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/dashboard/src/routes/drawers/devices/AccessPointDetail.page.tsx";
import PagefilesImport1 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/ClientDetail.page.tsx";

const routes = [
{path: "/clients/:macAddress",element: React.createElement(PagefilesImport1)},
{path: "/devices/:deviceName",element: React.createElement(PagefilesImport0)},
];

export default routes;