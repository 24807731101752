import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import InternetAndWireless from '../../../../components/Settings/InternetWiFi/InternetAndWireless';

export const Meta: PagefileMetaFn = () => ({
  path: '/internet-wifi',
});

export default function InternetAndWirelessPage() {
  return <InternetAndWireless />;
}
